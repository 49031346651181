import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "@glideapps/glide-data-grid/dist/index.css";
import axios from "../../utils/authAxios";
import {useUndoRedo} from "../../utils/undoRedo";
import {RiFileExcel2Line} from "react-icons/ri";
import {MdDownload} from "react-icons/md";
import {downloadXlsx} from "../../utils/fileDownload";
import {DataEditor} from "@glideapps/glide-data-grid";
import {useLayer, useMousePositionAsTrigger} from "react-laag";
import styles from './Spreadsheet.module.css'
import {Button, Checkbox, Input, List} from "antd";
import {GrSearch} from "react-icons/gr";
import {BsTrash3} from "react-icons/bs";
import {v4 as uuidv4} from "uuid";

const Spreadsheet = ({extractedData}) => {
    const containerRef = useRef(null);
    const gridRef = useRef(null);

    const [currentTab, setCurrentTab] = useState(0);
    const [sheets, setSheets] = useState([])
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const rowsRef = useRef(null)
    const [rowsInitialized, setRowsInitialized] = useState(false)
    const [showSearch, setShowSearch] = useState(false)

    const [filters, setFilters] = useState([])
    const [sorted, setSorted] = useState([])
    useEffect(() => {
        gridRef.current.focus()
    }, [sorted]);
    useEffect(() => {
        setFilters([...sheets.map(v => ({}))])
        setSorted([...sheets.map(v => null)])
    }, [sheets])

    const filteredRowIds = useMemo(() => {
        return filters.map(value => {
            const rowIds = Object.values(value).reduce((acc, filter) => {
                const rows = Object.values(filter).reduce((result, rowIds) => new Set([...result, ...rowIds]), new Set())
                return [...acc, rows]
            }, [])
            return rowIds.length ? rowIds.reduce((acc, set) => new Set([...acc].filter(x => set.has(x)))) : new Set()
        })
    }, [filters])
    const updateRows = useCallback(() => {
        setRows(rowsRef.current.map((value, index) => {
            const rowIds = [...value.keys()]
            return filteredRowIds[index]?.size ? rowIds.filter(rowId => filteredRowIds[index].has(rowId)) : rowIds
        }))
    }, [filteredRowIds])

    useEffect(() => {
        const preferredColumnOrder = JSON.parse(localStorage.getItem('columns')) ?? []
        const newColumn = extractedData.map(v => v.columns)
        const parseColumns = (columns) => (columns.map((c, i) => c.map(v => ({
            ...v,
            id: `${i}-${v.key}`,
            hasMenu: true,
            menuIcon: 'dots'
        }))))
        const sortColumn = (column) => ([...column].sort((a, b) => {
            if (a.key < b.key) return -1
            if (a.key > b.key) return 1
            return 0
        }))
        if (JSON.stringify(preferredColumnOrder.map(v => sortColumn(v))) === JSON.stringify(newColumn.map(v => sortColumn(v)))) setColumns(parseColumns(preferredColumnOrder))
        else setColumns(parseColumns(newColumn))
    }, [extractedData]);
    useEffect(() => {
        setSheets(extractedData.map(v => ({sheetId: v.sheet_id, sheetName: v.sheet_name})))
    }, [extractedData]);
    useEffect(() => {
        if (!extractedData.length || rowsInitialized) return
        rowsRef.current = extractedData.map(v => new Map(v.rows.map(row => {
            const rowId = uuidv4()
            return [rowId, {_rowId: rowId, ...row}]
        })))
        setRowsInitialized(true)
    }, [extractedData, rowsInitialized]);
    useEffect(() => {
        rowsInitialized && updateRows()
    }, [rowsInitialized, updateRows]);

    const onColumnResize = useCallback((column, newSize) => {
        setColumns(prevState => {
            const index = prevState[currentTab].findIndex(ci => ci.title === column.title)
            const currentColumn = [...prevState[currentTab]]
            currentColumn[index].width = newSize
            prevState.splice(currentTab, 1, currentColumn)
            return [...prevState]
        })
    }, [currentTab]);
    const onColumnMoved = useCallback((startIndex, endIndex) => {
        setColumns(prevState => {
            const newCols = [...prevState[currentTab]];
            const [toMove] = newCols.splice(startIndex, 1);
            newCols.splice(endIndex, 0, toMove);
            prevState.splice(currentTab, 1, newCols)
            localStorage.setItem('columns', JSON.stringify(prevState.map(c => c.map(v => ({
                key: v.key,
                title: v.title
            })))))
            return [...prevState];
        });
    }, [currentTab]);

    const [events, setEvents] = useState([])
    const onCellsEditedHandler = useCallback((newValues) => {
        newValues.forEach(newValue => {
            const [col, row] = newValue.location
            const value = newValue.value.data
            const rowId = rows[currentTab][row]
            const currentColumn = columns[currentTab][col]
            const newRow = rowsRef.current[currentTab].get(rowId)
            if (newRow[currentColumn.key] || value) {
                newRow[currentColumn.key] = value
                rowsRef.current[currentTab].set(rowId, newRow)
                setEvents(prevState => [...prevState, {rowId, key: currentColumn.key, value: value}])
            }
        })
    }, [currentTab, columns, rows]);
    const eventDataDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = {extraction_id: undefined, event_type: undefined, event_data: {}};
                return target[key];
            }
        });
    }
    useEffect(() => {
        if (events.length === 0) return
        const eventData = eventDataDefault()
        events.forEach(event => {
            const currentRow = rowsRef.current[currentTab].get(event.rowId)
            const extractionId = currentRow['_extraction_id']
            if (extractionId) {
                eventData[event.rowId].extraction_id = extractionId
            } else {
                eventData[event.rowId].event_data['_sheet_id'] = sheets[currentTab].sheetId
                eventData[event.rowId].event_data['_history_id'] = currentRow['_history_id'] || currentRow['_rowId']
            }
            eventData[event.rowId].event_type = 'update'
            eventData[event.rowId].event_data[event.key] = event.value
        })
        axios.put('/v1/events', Object.values(eventData)).then()
        setEvents([])
    }, [events, currentTab, rows, sheets]);

    const getData = useCallback(([col, row]) => {
        const cellData = `${rowsRef.current[currentTab].get(rows[currentTab][row])?.[columns[currentTab][col].key] || ''}`
        return {
            kind: 'text',
            allowOverlay: true,
            data: cellData,
            displayData: cellData,
        }
    }, [currentTab, columns, rows])

    const [insertedRow, setInsertedRow] = useState(null)
    const [deletedRows, setDeletedRows] = useState(null)

    const {
        gridSelection,
        onCellsEdited,
        onGridSelectionChange,
        changeRows,
        setChangeRows
    } = useUndoRedo(gridRef, getData, currentTab, onCellsEditedHandler, insertedRow, setInsertedRow, deletedRows, setDeletedRows);

    const [showMenu, setShowMenu] = useState(undefined)
    const {renderLayer: menuLayer, layerProps: menuLayerProps} = useLayer({
        isOpen: showMenu !== undefined,
        triggerOffset: 4,
        onOutsideClick: () => setShowMenu(undefined),
        trigger: {
            getBounds: () => ({
                left: showMenu?.bounds.x ?? 0,
                top: showMenu?.bounds.y ?? 0,
                right: (showMenu?.bounds.x ?? 0) + (showMenu?.bounds.width ?? 0),
                bottom: (showMenu?.bounds.y ?? 0) + (showMenu?.bounds.height ?? 0),
                width: showMenu?.bounds.width ?? 0,
                height: showMenu?.bounds.height ?? 0,
            }),
            getParent: () => containerRef.current
        },
        overflowContainer: false,
        placement: "bottom-end",
        auto: true,
        possiblePlacements: ["bottom-start", "bottom-end"],
    });

    const filteringCheckboxOptionsDataRef = useRef({})
    const [filteringCheckboxTempOptions, setFilteringCheckboxTempOptions] = useState([])
    const filteringCheckboxTempOptionsRef = useRef([])
    const [filteringCheckboxTempValues, setFilteringCheckboxTempValues] = useState([])
    const filteringCheckboxTempValuesRef = useRef([])
    const checkAll = filteringCheckboxTempOptions.length === filteringCheckboxTempValues.length
    const filteringOptionsDataDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = new Set()
                return target[key];
            }
        });
    }
    const onHeaderMenuClick = useCallback((col, bounds) => {
        setShowMenu({col, bounds})
        const currentRows = rowsRef.current[currentTab]
        const key = columns[currentTab][col].key
        const optionsData = filteringOptionsDataDefault()
        const otherFilters = Object.entries(filters[currentTab])
            .filter(value => value[0] !== key)
            .map(value => new Set(Object.values(value[1]).flatMap(rowIds => [...rowIds])))
        if (filters[currentTab][key]) {
            if (otherFilters.length) {
                const candidates = new Set([...otherFilters.reduce((acc, set) => new Set([...acc].filter(x => set.has(x))))].map(value => currentRows.get(value)[key]))
                currentRows.entries().forEach(v => candidates.has(v[1][key]) && optionsData[v[1][key]].add(v[0]))
            } else currentRows.entries().forEach(v => optionsData[v[1][key]].add(v[0]))
        } else {
            const candidates = new Set(rows[currentTab].map(value => currentRows.get(value)[key]))
            currentRows.entries().forEach(v => candidates.has(v[1][key]) && optionsData[v[1][key]].add(v[0]))
        }
        const allOptions = Object.keys(optionsData)
        const tempValues = filters[currentTab][key] ? Object.keys(filters[currentTab][key]) : allOptions
        const tempOptions = [...tempValues.sort((a, b) => {
            if (a === "" && b !== "") return 1;
            if (a !== "" && b === "") return -1;
            return a.localeCompare(b);
        }), ...Array.from(new Set(allOptions).difference(new Set(tempValues))).sort((a, b) => {
            if (a === "" && b !== "") return 1;
            if (a !== "" && b === "") return -1;
            return a.localeCompare(b);
        })]
        filteringCheckboxOptionsDataRef.current = optionsData
        filteringCheckboxTempOptionsRef.current = tempOptions
        filteringCheckboxTempValuesRef.current = tempValues
        setFilteringCheckboxTempOptions(tempOptions)
        setFilteringCheckboxTempValues(tempValues)
    }, [currentTab, columns, rows, filters])
    const onFilteringInputChange = useCallback(e => {
        const inputOptions = Object.keys(filteringCheckboxOptionsDataRef.current).filter(v => v.toLowerCase().includes(e.target.value.toLowerCase())).sort()
        if (e.target.value) {
            setFilteringCheckboxTempValues(inputOptions)
            setFilteringCheckboxTempOptions(inputOptions)
        } else {
            setFilteringCheckboxTempValues(filteringCheckboxTempValuesRef.current)
            setFilteringCheckboxTempOptions(filteringCheckboxTempOptionsRef.current)
        }
    }, [filteringCheckboxOptionsDataRef, filteringCheckboxTempValuesRef, filteringCheckboxTempOptionsRef])
    const onFilteringCheckboxChange = useCallback(list => setFilteringCheckboxTempValues(prevState => Array.from(new Set(prevState).difference(new Set(filteringCheckboxTempOptions)).union(new Set(list)))), [filteringCheckboxTempOptions])
    const onFilteringCheckboxAllChange = useCallback(e => setFilteringCheckboxTempValues(e.target.checked ? filteringCheckboxTempOptions : []), [filteringCheckboxTempOptions])
    const onFilterApply = useCallback(() => {
        setFilters(prevState => prevState.map((prevFilter, i) => {
            if (i === currentTab) {
                if (Object.keys(filteringCheckboxOptionsDataRef.current).length !== filteringCheckboxTempValues.length) {
                    prevFilter[columns[currentTab][showMenu.col].key] = filteringCheckboxTempValues.reduce((acc, key) => {
                        acc[key] = filteringCheckboxOptionsDataRef.current[key]
                        return acc
                    }, {})
                } else {
                    delete prevFilter[columns[currentTab][showMenu.col].key]
                }
            }
            return prevFilter
        }))
        setShowMenu(undefined)
    }, [currentTab, columns, showMenu, filteringCheckboxOptionsDataRef, filteringCheckboxTempValues])
    const onSort = useCallback(order => {
        const currentRows = rowsRef.current[currentTab]
        const key = columns[currentTab][showMenu.col].key
        setRows(prevState => {
            prevState[currentTab] = prevState[currentTab].sort((a, b) => {
                const [aKey, bKey] = [currentRows.get(a)[key], currentRows.get(b)[key]]
                if (aKey < bKey) return order === 'asc' ? -1 : 1
                if (aKey > bKey) return order === 'asc' ? 1 : -1
                return 0
            })
            return prevState
        })
        setSorted(prevState => {
            const newState = [...prevState]
            newState[currentTab] = {column: key, isDesc: order === 'desc'}
            return newState
        })
        setShowMenu(undefined)
    }, [columns, currentTab, showMenu])

    const svgIconsRef = useRef({})
    useEffect(() => {
        const svg = {
            sortAsc: `<svg stroke="#75706B" fill="#75706B" stroke-width="1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"></path></svg>`,
            sortDesc: `<svg stroke="#75706B" fill="#75706B" stroke-width="1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"></path></svg>`,
            filter: `<svg stroke="#75706B" fill="#75706B" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0zM0 0h24m0 24H0"></path><path d="M4.25 5.66c.1.13 5.74 7.33 5.74 7.33V19c0 .55.45 1 1.01 1h2.01c.55 0 1.01-.45 1.01-1v-6.02s5.49-7.02 5.75-7.34C20.03 5.32 20 5 20 5c0-.55-.45-1-1.01-1H5.01C4.4 4 4 4.48 4 5c0 .2.06.44.25.66z"></path></svg>`
        }
        Object.entries(svg).forEach(([key, value]) => {
            const img = new Image();
            img.src = 'data:image/svg+xml;base64,' + btoa(decodeURI(value));
            img.onload = () => svgIconsRef.current[key] = img;
        })
    }, []);
    const drawHeader = useCallback((args, draw) => {
        const {x, y, width, height} = args.rect;

        const iconSize = 16;
        const iconX = x + width - iconSize - 2;
        const iconY = y + height / 2 - iconSize / 2;
        draw()
        if (!args.isHovered) {
            if (filters.length && sorted.length && args.columnIndex !== -1) {
                const filteredColumns = Object.keys(filters[currentTab])
                const sortedColumn = sorted[currentTab]
                const key = columns[currentTab][args.columnIndex].key
                let isFiltered = false
                args.ctx.fillStyle = args.hasSelectedCell ? 'rgba(233, 233, 235, 0.9)' : 'rgba(247, 247, 248, 0.9)'
                if (filteredColumns.includes(key)) {
                    args.ctx.fillRect(iconX - 8, iconY - 5, iconSize + 10, iconSize + 10)
                    isFiltered = true
                    args.ctx.drawImage(svgIconsRef.current.filter, iconX, iconY, iconSize, iconSize)
                }
                if (sortedColumn?.column === key) {
                    const sortIcon = svgIconsRef.current[sortedColumn.isDesc ? 'sortDesc' : 'sortAsc']
                    if (isFiltered) args.ctx.drawImage(sortIcon, iconX - 6, iconY + 4, iconSize - 4, iconSize - 4)
                    else {
                        args.ctx.fillRect(iconX - 8, iconY - 5, iconSize + 10, iconSize + 10)
                        args.ctx.drawImage(sortIcon, iconX, iconY, iconSize, iconSize)
                    }
                }
            }
        }
    }, [columns, currentTab, filters, sorted])

    const {
        hasMousePosition: showContextMenu,
        resetMousePosition: closeContextMenu,
        handleMouseEvent,
        trigger
    } = useMousePositionAsTrigger();
    const {renderLayer: contextMenuLayer, layerProps: contextMenuLayerProps} = useLayer({
        isOpen: showContextMenu,
        onOutsideClick: closeContextMenu,
        trigger,
        placement: 'right-start',
        auto: true,
    });
    const selectedRows = useMemo(() => {
        if (gridSelection?.current) {
            const range = gridSelection.current.range
            return [{start: range.y, end: range.y + range.height - 1}]
        } else if (gridSelection?.rows) {
            return gridSelection.rows.items.map(v => ({start: v[0], end: v[1] - 1}))
        } else return []
    }, [gridSelection])
    const onRowsDelete = useCallback(() => {
        closeContextMenu()
        setDeletedRows(selectedRows.map(v => ({
            prevRow: rows[currentTab][v.start - 1],
            rows: rows[currentTab].slice(v.start, v.end + 1).map(rowId => rowsRef.current[currentTab].get(rowId))
        })))
    }, [closeContextMenu, selectedRows, rows, currentTab])
    const onRowAppended = useCallback(() => {
        const rowId = uuidv4()
        filteredRowIds[currentTab].size && filteredRowIds[currentTab].add(rowId)
        setInsertedRow({
            prevRow: rows[currentTab][rows[currentTab].length - 1],
            rows: [{
                _rowId: rowId, ...columns[currentTab].reduce((acc, row) => {
                    acc[row.key] = ''
                    return acc
                }, {})
            }]
        })
    }, [currentTab, rows, filteredRowIds, columns])
    useEffect(() => {
        if (changeRows) {
            const isDelete = changeRows.operation === 'delete'
            if (isDelete) {
                changeRows.rows.forEach(change => change.rows.forEach(v => rowsRef.current[currentTab].delete(v._rowId)))
            } else { // insert
                const entries = Array.from(rowsRef.current[currentTab].entries())
                changeRows.rows.forEach(change => {
                    const index = entries.findIndex(([key]) => key === change.prevRow)
                    entries.splice(index + 1, 0, ...change.rows.map(v => [v._rowId, v]))
                })
                rowsRef.current[currentTab] = new Map(entries)
            }
            updateRows()
            const eventData = changeRows.rows.flatMap(change => change.rows.map(v => {
                const {_extraction_id, _rowId, ...data} = v
                if (_extraction_id) {
                    return {
                        extraction_id: _extraction_id,
                        event_type: isDelete ? 'delete' : 'update',
                    }
                } else {
                    if (Object.keys(data).length) {
                        return {
                            event_type: isDelete ? 'delete' : 'update',
                            event_data: {
                                _sheet_id: sheets[currentTab].sheetId,
                                _history_id: _rowId,
                                ...data
                            }
                        }
                    }
                }
                return null
            })).filter(v => v)
            eventData.length && axios.put('/v1/events', eventData).then()
            setChangeRows(null)
        }
    }, [changeRows, setChangeRows, currentTab, updateRows, sheets]);

    useEffect(() => {
        const onKeyDown = (event) => {
            if (event.code === "KeyF" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                event.stopPropagation();
                setShowSearch(v => !v);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown)
        };
    }, []);

    return (
        <div ref={containerRef} className={'space-y-3 h-full flex flex-col'}>
            <div className="flex space-x-3">
                {sheets.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentTab(index)}
                        className={`${
                            currentTab === index ? "font-medium bg-gray-200 text-blue-500" : "bg-gray-100 text-gray-600"
                        } px-4 h-9 rounded-lg text-sm flex-none`}>
                        {item.sheetName}
                    </button>
                ))}
                <div className={'flex-grow'}/>
                <div className={'flex flex-none'}>
                    <button onClick={() => downloadXlsx(extractedData)}
                            className="px-2 py-1 bg-gray-100 rounded-lg text-xs text-green-700 flex space-x-2 items-center transition hover:bg-gray-200">
                        <RiFileExcel2Line className="w-4 h-4"/>
                        <p>Export to Excel</p>
                        <MdDownload className="w-4 h-4"/>
                    </button>
                </div>
            </div>
            <div className="flex-1 overflow-auto text-gray-700 space-y-8 pb-2">
                <div className="h-full text-xs font-WantedSans" style={{minHeight: "300px"}}
                     onContextMenu={handleMouseEvent}>
                    <DataEditor ref={gridRef}
                                columns={rows.length && columns.length ? columns[currentTab] : []}
                                getCellContent={getData}
                                rows={rows[currentTab]?.length ?? 0}
                                rowMarkers={'clickable-number'}
                                rowSelect={'multi'}
                                rowHeight={24}
                                headerHeight={26}
                                getCellsForSelection={true}
                                width={'100%'}
                                height={'100%'}
                                onPaste={true}
                                showSearch={showSearch}
                                onSearchClose={() => setShowSearch(false)}
                                gridSelection={gridSelection ?? undefined}
                                onGridSelectionChange={onGridSelectionChange}
                                drawHeader={drawHeader}
                                onHeaderMenuClick={onHeaderMenuClick}
                                onColumnResize={onColumnResize}
                                onColumnMoved={onColumnMoved}
                                onCellsEdited={onCellsEdited}
                                trailingRowOptions={{sticky: true, tint: true, hint: "New row"}}
                                onRowAppended={onRowAppended}
                                onCellContextMenu={() => setShowMenu(undefined)}/>
                    <div id="portal" style={{position: 'fixed', left: 0, top: 0, zIndex: 9999}}/>
                    {showMenu && menuLayer(
                        <div {...menuLayerProps} className={styles.menu}>
                            <List className={'mb-2'}
                                  dataSource={[{'key': 'asc', 'text': 'Sort ▲'}, {'key': 'desc', 'text': 'Sort ▼'}]}
                                  renderItem={(item) => <List.Item className={styles.item}
                                                                   onClick={() => onSort(item.key)}>{item.text}</List.Item>}/>
                            <div className={'px-2 mb-2 flex flex-col'}>
                                <Input onChange={onFilteringInputChange} suffix={<GrSearch/>}/>
                                <Checkbox className={'ml-auto'} checked={checkAll}
                                          onChange={onFilteringCheckboxAllChange}>Select all</Checkbox>
                                <Checkbox.Group style={{height: '10rem'}}
                                                className={`${styles.checkboxGroup} py-1 overflow-y-auto flex flex-col flex-nowrap border`}
                                                value={filteringCheckboxTempValues}
                                                options={filteringCheckboxTempOptions.map(value => ({
                                                    label: value || '(Blank)',
                                                    value: value
                                                }))}
                                                onChange={onFilteringCheckboxChange}/>
                            </div>
                            <div className={'flex flex-row justify-end space-x-3 px-2'}>
                                <Button type={'primary'} onClick={onFilterApply}
                                        disabled={!filteringCheckboxTempValues.length}>OK</Button>
                                <Button onClick={() => setShowMenu(undefined)}>Close</Button>
                            </div>
                        </div>
                    )}
                    {showContextMenu && contextMenuLayer(
                        <div {...contextMenuLayerProps} className={styles.menu}>
                            <List>
                                {selectedRows.length > 0 &&
                                    <List.Item className={`${styles.item}`} onClick={onRowsDelete}>
                                        <BsTrash3 className={'mr-1'}/>
                                        <span>Delete {selectedRows.length > 1 ? 'selected rows' : `row${selectedRows[0].start !== selectedRows[0].end ? `s ${selectedRows[0].start + 1} - ${selectedRows[0].end + 1}` : ''}`}</span>
                                    </List.Item>}
                            </List>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Spreadsheet;
